export const Logo = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 248.5 285.33"
		style={{ width: "30px" }}
	>
		<title>{"Clay Diffrient"}</title>
		<defs>
			<style>{".cls-2{fill:#76a89a;opacity:.49}"}</style>
		</defs>
		<g id="Layer_2" data-name="Layer 2">
			<g id="claylogo_Image" data-name="claylogo Image">
				<path
					fill="#90cc47"
					d="M89.14 62.88a93.58 93.58 0 0 1 52.11-15.77V0a139.77 139.77 0 0 0-76.2 22.49l24.08 40.39z"
				/>
				<path d="m89.22 62.88-1-1.7 1 1.75z" className="cls-2" />
				<path
					fill="#87bf43"
					d="m89.16 62.93-1-1.75-23.03-38.69a145.65 145.65 0 0 0-52.78 61.83l42.14 20.24a95.71 95.71 0 0 1 34.67-41.63z"
				/>
				<path
					fill="#7db33f"
					d="M46.67 142.67a96.1 96.1 0 0 1 7.82-38.11L12.35 84.32A143.24 143.24 0 0 0 0 142.67a144.78 144.78 0 0 0 1.45 20.42l46.2-6.66a97.53 97.53 0 0 1-.98-13.76z"
				/>
				<path
					fill="#70a139"
					d="m47.65 156.43-46.2 6.66a142.62 142.62 0 0 0 33.62 73.62L69.59 205a95.61 95.61 0 0 1-21.94-48.57z"
				/>
				<path d="m69.59 205-34.52 31.71 34.8-31.41z" className="cls-2" />
				<path
					fill="#518f32"
					d="M115.52 234.63a96.28 96.28 0 0 1-46-29.69l-34.44 31.79a141.24 141.24 0 0 0 65 42.41l15.48-44.51z"
				/>
				<path d="M100 279.14c.31.1.63.18.94.27l5.68-19.31z" className="cls-2" />
				<path
					fill="#47852f"
					d="M141.33 238.23a93.94 93.94 0 0 1-25.81-3.6l-15.61 44.42a147.62 147.62 0 0 0 41.42 6.28 140.34 140.34 0 0 0 39.28-5.58l-12.56-45.39a94 94 0 0 1-26.72 3.87z"
				/>
				<path
					fill="#397a32"
					d="m168.05 234.36 12.56 45.39a141.36 141.36 0 0 0 67.89-44.07l-35.88-30.17a94.47 94.47 0 0 1-44.57 28.85z"
				/>
				<path
					fill="#78ab3c"
					d="M208.49 94.52c-.45-25.52.42-51.06.09-76.58 0-.94-.07-17.08 0-17.08h-33.33v84a67.66 67.66 0 1 0-34.36 126c25.88 0 52.86-16.3 62.47-40.92 5-12.71 5.33-26.11 5.36-40.1.03-11.74-.02-23.59-.23-35.32zm-67.16 81.91a33.34 33.34 0 1 1 33.34-33.34 33.34 33.34 0 0 1-33.34 33.34z"
				/>
			</g>
		</g>
	</svg>
);
