import { Link } from "@remix-run/react";
import { Logo } from "./Logo";

function Links() {
	const links = [
		{ label: "Blog", href: "/blog" },
		// { label: "IDET", href: "/idet" },
	];

	return links.map((link) => (
		<li key={link.href}>
			<Link to={link.href}>{link.label}</Link>
		</li>
	));
}

export default function Header() {
	return (
		<div className="navbar bg-base-100 rounded-box">
			<div className="navbar-start">
				<div className="justify-start p-1">
					<Link to="/" className="flex flex-wrap justify-start content-center">
						<Logo />
						<span className="text-primary font-siteheader lowercase block mt-1 ml-1">
							Clay Diffrient
						</span>
					</Link>
				</div>
			</div>
			<div className="navbar-end lg:flex">
				<ul className="menu menu-horizontal px-1">
					<Links />
				</ul>
			</div>
		</div>
	);
}
